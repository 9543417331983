import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../../components/form-components";
import { requiredValidator, fieldValidator } from "../../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../../components/ApiStatus";
import { PageLoader } from "../../../components/PageLoader";
import { PageMutating } from "../../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useFetchUsers } from "../../../services/userService";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

const ValidatedInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};



const payloadGetter = getter("payload");

const triggerEventFormValidator = (values, isError, error) => {  
  // if(isError){
  //   var errors = error.response?.data;
  //   // combining errors related to same field
  //   var errorsMap = Object.entries(errors).map(([key, value]) => ({ [key]: value.join(', ')}));
  //   // converting array of object to one object with fields
  //   const errorReduce = errorsMap.reduce((k, v)=> Object.assign(k, v));
  //   var res = {
  //     VALIDATION_SUMMARY: "Please fix the following:",
  //     ...errorReduce
  //   };    
  //   //debugger
  //     return res;
  // }
  if (payloadGetter(values)) {
    return;
  }  
  return {
    VALIDATION_SUMMARY: "Please fill at least one of the following fields.",
    ["payload"]:
      "Please check the validation summary for more information.",    
  };
};

export const DepartmentForm = ({ data, eventMutation, isNew = false }) => {      

  const {isLoading } = eventMutation; 
  const [userOptions, setUserOptions] = React.useState([]);

  const { data: users, status : usersStatus, isSuccess : usersIsSuccess, isError : usersisError, error: usersError } = 
  useFetchUsers(toDataSourceRequestString({
    sort: [
      { field: "email", dir: "asc" }
    ],
    filter: {
      logic: 'and',
      filters: [
        { field: 'email', operator: 'contains', value: '@law.ucla.edu' }
      ]
    },
    skip: 0,
    take: 10,
  }));  

  if (!usersIsSuccess) return <ApiStatus status={usersStatus}></ApiStatus>;
  if (!users) return <div> Not found.</div>;          
   
  return (
    <>
      <DepartmentFormHelper data={data} eventMutation={eventMutation} isNew={isNew} users={users?.data} />
    </>
  );
};

export const DepartmentFormHelper = ({ data, eventMutation, isNew = false, users }) => {      

  const {isLoading } = eventMutation; 
  const [userOptions, setUserOptions] = React.useState(users);  
  
  data.members = data?.members?.map((d) => ({
    id: d.id,
    email: users?.find((i) => i.id === d.id)?.email ?? d.id,
  }));

  const fieldChange = (e) =>
  {            
    const fieldName = e.target.name;    
    //if(fieldName === "members")
      //setUserOptions(userOptions.filter((i) => i.email.startsWith(e.value.id)));
    //debugger;
    if(eventMutation.error?.response?.data == null)        
      return;
    delete eventMutation.error.response.data[fieldName];    
  }  
  
  const handleFilterChange = (e) => {    
    setUserOptions(users.filter((i) => i.email.startsWith(e.filter.value)));    
  };

  return (
    <>        
    <Form
      onSubmit={(d) => 
        {          
          return eventMutation.mutate( 
            {...d , 
              modifiedAt:  (DateTime.fromJSDate(new Date())).toISO(),               
      })}}
      //onSubmit={(d) => console.log(d)}
      //validator={(v) => triggerEventFormValidator(v,triggerEventMutation.isError, triggerEventMutation.error) }
      initialValues={data}                        
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          {/* <p> hi {data?.triggeredBy}</p> */}
          <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
              Please fill in the following information:
            </legend>
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                                                          
              <Field 
                name={"name"} 
                component={ValidatedInput} 
                onChange={fieldChange}                            
                label={"Name"} 
              />

              <Field 
                name={"description"} 
                component={ValidatedInput} 
                onChange={fieldChange}                                     
                label={" Description"} 
              />                    

              { !isNew &&  <Field 
                        name={"members"} 
                        component={MultiSelect}                                                 
                        data={userOptions}
                        onChange={fieldChange}                              
                        onFilterChange={handleFilterChange}
                        textField="email"
                        filterable={true}
                        dataItemKey="id"
                        label={"Members"} 
                        //validator={(d) => fieldValidator(d,"departments", eventMutation.error, requiredValidator)} 
                      />   
              }    

          </fieldset>
          <div className="k-form-buttons">
            <button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={!formRenderProps.allowSubmit || isLoading}
            >              
              Submit
            </button>            
           <PageMutating/>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

