import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useFetch, useFetchWithPost } from "./useFetch";
import { usePut } from "./usePut";
import { usePost } from "./usePost";
import PropTypes from "prop-types";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "./useDelete";
import { redirect } from "react-router-dom";

export const triggerEvents = [
  {
    id: "CanvasExamSubmissionFetchTriggerEvent",
    text: "Canvas Exam Submission Fetch",
  },
  { id: "TestTriggerEvent", text: "Test" },
];

export const useFetchScholarships = (query) => {
  return useFetch({
    url: `Scholarship`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return { ...r, triggerAt: new Date(r.triggerAt) };
        }),
      };
    },
    query: query,
  });
};

export const useFetchScholarship = (id) => {
  return useFetch({
    url: `Scholarship/${id}`,
    dataMapper: (d) => {
      return {
        ...d,
        dateOfBirth: new Date(d.dateOfBirth),
        expectedGraduationYear: {
          id: d.expectedGraduationYear,
          text: d.expectedGraduationYear,
        },
      };
    },
  });
};

export const useUpdateScholarship = (stepId, lastStepIndex) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  const steps = ["savePersonalInfo", "savePersonalDetails", "submit"];

  return usePut(`Scholarship/{id}/${steps[stepId]}`, {
    onSuccess: (_, scholarship) => {
      queryClient.invalidateQueries([`Scholarship/${scholarship.id}`]);
      //debugger;
      if (stepId === lastStepIndex) {
        nav("/Scholarships");
      } else {
        nav(
          `/scholarships/${scholarship.id}/edit&step=${Math.min(
            stepId + 1,
            lastStepIndex
          )}`,
          { replace: true }
        );
      }

      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useDeleteScholarship = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`Scholarship/{id}`, {
    onSuccess: (_, data) => {
      queryClient.invalidateQueries([`Scholarship/${data.id}`]);
      queryClient.invalidateQueries(["Scholarship"]);
      nav(`/scholarships`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

// export const useCreateScholarship = () => {
//   return useFetchWithPost({
//     url: `Scholarship`,
//     dataMapper: (d) => d,
//   });
// };

export const useCreateScholarship = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return usePost(`Scholarship`, {
    onSuccess: (_, scholarship) => {
      //queryClient.invalidateQueries([`TriggerEvent/${triggerEvent.id}`]);
      //debugger;
      nav(`/scholarships/${_.data.id}/edit&step=0`);
      // setNotifications([
      //   ...notifications,
      //   {
      //     id: uuidv4(),
      //     style: "success",
      //     text: "Your data has been saved.",
      //   },
      // ]);
      //debugger;
      //console.log(triggerEvent);
      return _;
    },
    onprogress: (_, scholarship) => {},
  });
};
