import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
export const YearDropDownCell = (props) => {

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.id,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  return (
    <td>
      {dataItem.isNew ? (
        <DropDownList
          
          onChange={handleChange}
          value={dataItem.yearOptions.find((c) => c.id === dataValue)}
          data={dataItem.yearOptions}          
          textField="text"
        />
      ) : dataValue }
    </td>
  );
};