//import './CourseEvaluations.css';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { DateTime, Settings } from "luxon";
import {useFetchGiftSources, useDeleteGiftSource} from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, ViewCommandCell, EditCommandCell } from "../../../components/GridComponents.jsx";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb.jsx";
import { Button } from '@progress/kendo-react-buttons';

const StatefullGrid = WithState(Grid);

export const GiftSourcesGrid = () => {
  const filterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
      { text: "grid.filterIsEmptyOperator", operator: "isempty" },
      { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    date: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
      { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    boolean: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
    ],
  };
  
  const deleteMutation = useDeleteGiftSource();    

  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "name", dir: "asc" }
    ],
    skip: 0,
    take: 10,
  });

  const [gridData, setGridData] = React.useState([]);

  const navigate = useNavigate();

  const enterView = (item) => {
    navigate(`${item.id}`);
  };

  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );

  const RecordDeleteCommandCell = (props) => (    
    <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
  );  

  const GiftCommandCell = (props) => {
    return (
      <>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterView(props.dataItem)}
        > <span className="k-icon"></span>          
          Gift Aids
        </button>
      </>
    );
  };

  const RecordGiftAidCommandCell = (props) => (
    <ViewCommandCell {...props} name="GiftAid" enterView={enterGigtAid} />
  );

  const RecordCombinedCommandCell = (props) => (
    <td className="record-combined-command-cell">
      <GiftCommandCell {...props} enterView={enterGigtAid} />
      <ViewCommandCell {...props} enterView={enterView}  />
      <EditCommandCell {...props} enterEdit={enterEdit} />      
      <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
    </td>
  );

  const enterGigtAid = (item) => {     
    navigate(`GS-${item.id}/giftaids`);
  }  

  const enterEdit = (item) => {     
    navigate(`GS-${item.id}/edit`);
  }  

  return (
    <>      
     <MyBreadcrumb breadcrumbItems={[          
            { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources` },            
          ]} 
        />
       {/* <div style={{margin:'10px 0px'}}>
        <button className="btn btn-primary"  onClick={() => navigate('new') }> New </button>              
      </div>     */}
      <StatefullGrid
        apiDataSource={useFetchGiftSources}
        filterOperators={filterOperators}
        sortable={true}
        pageable={true}
        resizable={true}
        // data={d => d.map((item) => ({
        //   ...item,
        //   inEdit: item.srsNumber === editID,
        // }))}
        minResizableWidth={30}                        
        dataState={gridDataState}
        gridData={gridData}
        setGridDataState={setGridDataState}
        setGridData={setGridData}
        updateURLSearchParams = {true}        
      > 
      <GridToolbar>       
          <Button title="Add new" icon="plus" themeColor={'primary'} onClick={() => navigate('new')} type="button">
              Gift Source
          </Button>
        </GridToolbar>
        <GridColumn field="prefixedId" title="ID" filterable={true} />       
        <GridColumn field="name" title="Name" filterable={true} />     
        <GridColumn field="fundNumber" title="FoundNumber" filterable={true} />
        <GridColumn field="foundationNumber" title="FoundationNumber" filterable={true} />
        <GridColumn field="type" title="Type" filterable={true} />                   
        <GridColumn field="amount" title="Amount" filterable={true} />        
        <GridColumn field="departments" title="Departments" filterable={true} />               
        {/* <GridColumn field="adminNotes" title="Admin Notes" filterable={true} />                                 */}
        {/* <GridColumn field="dateEstablished" title="Date Established" filter="date" format="{0:MM/dd/yyyy}" /> */}
        <GridColumn field="modifiedAt" title="Modified At" filter="date" format="{0:MM/dd/yyyy}" />
        {/* <GridColumn field="modifiedBy" title="Modified By" filterable={true} />                                  */}
        
        {/* <GridColumn cell={RecordGiftAidCommandCell} filterable={false}  width={"120px"}/>        
        <GridColumn cell={RecordEditCommandCell} filterable={false}  width={"100px"}/> */}
        <GridColumn cell={RecordCombinedCommandCell} filterable={false} width={"270px"} />

      </StatefullGrid>
    </>
  );
};
