//import './CourseEvaluations.css';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { WithState } from "../../components/withState.js";
import {useFirstYearCourses, useCreateFirstYearPreferences, useUpdateCourse } from "../../services/firstYearPreferenceService.js";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { filterBy } from "@progress/kendo-data-query";
import {
  Filter,
  Operators,
  TextFilter,
  NumericFilter,
  BooleanFilter,
} from "@progress/kendo-react-data-tools";
import { MyCommandCell } from "./components/MyCommandCell.jsx";
import {CategoryDropDownCell} from "./components/CategoryDropDownCell.jsx";
import { DateTime, Settings } from "luxon";
import {
  useDeleteFirstYearPreference
} from "../../services/firstYearPreferenceService.js";

const editField = "inEdit";
const CommandCell = (props) => {
  const { edit, remove, add, discard, update, cancel, editField } = props;
  return (
    <MyCommandCell
      {...props}
      edit={edit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );
};


const StatefullGrid = WithState(Grid);

export const FirstYearCoursesGrid = () => {
  const filterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
      { text: "grid.filterIsEmptyOperator", operator: "isempty" },
      { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    date: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
      { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    boolean: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
    ],
  };

  const [editID, setEditID] = React.useState(null);
  const deleteMutation = useDeleteFirstYearPreference();
  const createMutation = useCreateFirstYearPreferences();
  const updateMutation = useUpdateCourse();

  const rowClick = (event) => {
    //debugger;
    setEditID(event.dataItem.srsNumber);
  };

  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "srsNumber", dir: "asc" }
    ],
    skip: 0,
    take: 10,
  });

  const [gridData, setGridData] = React.useState([]);
  const [gridDataOrig, setGridDataOrig] = React.useState([]);

  const navigate = useNavigate();

  // modify the data in the store, db etc
  const remove = (dataItem) => {
    // const newData = [...deleteItem(dataItem)];
    // setData(newData);
  };
  const add = (dataItem) => {
    // dataItem.inEdit = true;
    // const newData = insertItem(dataItem);
    // setData(newData);
  };
  const update = async (dataItem) => {

    const res = await updateMutation.mutateAsync({...dataItem, modifiedAt:  (DateTime.fromJSDate(new Date())).toISO()});
    // dataItem.inEdit = false;
    // const newData = updateItem(dataItem);
    // setData(newData);
    setGridData(
      {...gridData, data:gridData.data.map(
        item => item.srsNumber === dataItem.srsNumber ? { ...item, inEdit: false} : item
      )
      }    
    );
    gridDataOrig[dataItem.termId + dataItem.srsNumber] = dataItem;
  };

   // Local state operations
   const discard = () => {
    // const newData = [...data];
    // newData.splice(0, 1);
    // setData(newData);
  };
  const cancel = (dataItem) => {    
    var origItem = gridDataOrig[dataItem.termId + dataItem.srsNumber];
    setGridData(
      {...gridData, data:gridData.data.map(
        item => item.srsNumber === dataItem.srsNumber && item.termId === dataItem.termId ? { ...origItem, inEdit: false} : item
      )
      }    
    );       
  };

  const enterEdit = (dataItem) => {     
    setGridData(
        {...gridData, data:gridData.data.map(
          item => item.srsNumber === dataItem.srsNumber && item.termId === dataItem.termId  ? {...item, inEdit: true} : item
        )
        }    
      );
      const idToRow = gridData.data.reduce((acc, item, index) => {;
        acc[item.termId + item.srsNumber] = item;
        return acc;
      }, {});
      setGridDataOrig(idToRow); 
  }

  const itemChange = (event) => {    
    const newData = {...gridData, data:gridData.data.map(
      item => item.srsNumber === event.dataItem.srsNumber && item.termId === event.dataItem.termId ? {...item, [event.field || ""]: event.value} : item
    )}    
    setGridData(newData);
  };

  const addNew = () => {
    // const newDataItem = {
    //   inEdit: true,
    //   Discontinued: false,
    // };
    // setData([newDataItem, ...data]);
  };

  const commandCellProps = {
    edit: enterEdit,
    remove: remove,
    add: add,
    discard: discard,
    update: update,
    cancel: cancel,
    editField: editField,
  };

  return (
    <>      
      {/* <div style={{margin:'10px 0px'}}>
        <button className="btn btn-primary" onClick={() => createMutation.mutate() }> New </button>      
      </div>       */}
      <StatefullGrid
        apiDataSource={useFirstYearCourses}
        filterOperators={filterOperators}
        sortable={true}
        pageable={true}
        resizable={true}
        // data={d => d.map((item) => ({
        //   ...item,
        //   inEdit: item.srsNumber === editID,
        // }))}
        minResizableWidth={30}
        onItemChange={itemChange}
        editField="inEdit"
        onRowClick={rowClick}
        //onRowClick={NavigateToCourseEvaluate}
        dataState={gridDataState}
        gridData={gridData}
        setGridDataState={setGridDataState}
        setGridData={setGridData}
        updateURLSearchParams = {true}        
      >
        {/* <GridColumn field="id" title="id" filterable={false} /> */}            
        <GridColumn field="termId" title="Term Id" filterable={true} editable={false} />                        
        <GridColumn field="srsNumber" title="SrsNumber" filterable={true}  editable={false}/>                        
        <GridColumn field="courseNum" title="Course Num" filterable={true}  editable={false}/>                  
        <GridColumn field="title" title="Title" filterable={true}  editable={true}/>                        
        <GridColumn field="instructorLastName" title="Instructor LastName"  filterable={true} />                        
        <GridColumn field="instructorFirstName" title="Instructor FirstName" filterable={true}  editable={true} />                        
        <GridColumn field="category" title="Category" cell={CategoryDropDownCell} filterable={true} width="200px" />                        
        <GridColumn field="rankingRequired" title="rankingRequired" editor="boolean" filterable={true} />                                
        <GridColumn field="capacity" title="Capacity" editor="numeric" filterable={true} />                                        
        {/* <GridColumn
          field="createdAt"
          title="Created At"
          filterable={false}
          format="{0:dd-MMM-yyyy HH:mm Z}"
        />  */}
        {/* <GridColumn cell={RecordViewCommandCell} filterable={false}  width={"110px"}/>
        <GridColumn cell={RecordEditCommandCell} filterable={false}  width={"100px"}/>
        <GridColumn cell={RecordDeleteCommandCell} filterable={false} width={"120px"} /> */}
        <GridColumn filterable={false}
          cell={(props) => <CommandCell {...props} {...commandCellProps} />}
          width="200px"
        />

      </StatefullGrid>
    </>
  );
};
