import React from "react";
import { Breadcrumb, BreadcrumbListItem } from "@progress/kendo-react-layout";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon, SvgIcon } from '@progress/kendo-react-common';
import { facebookIcon, twitterIcon, googleIcon, linkedinIcon, youtubeIcon } from '@progress/kendo-svg-icons';

export const MyBreadcrumb = ({ breadcrumbItems }) => {
  const navigate = useNavigate();  
  const location = useLocation();

  const handleNavigate = (event) => {    
    const foundItem = breadcrumbItems.find(item => item.id === event.id);
    navigate(foundItem.url);
  };


  const currentPath = location.pathname;
  const currentIndex = breadcrumbItems?.findIndex((item) => item.url === currentPath) ?? 0;

  return (    
    <>
      <Breadcrumb    
          data={breadcrumbItems.map((item, index) => ({
            ...item,
            text: item.text,          
            navigable: index <= currentIndex,  // Enable navigation only for past items
            url: item.url,                        
            icon: <div style={{margin: '0 2px' }}><Icon name={item.iconName} /></div> 
          }))}                    
          onItemSelect={(event) => handleNavigate(event)}                    
      />
       
      <hr/>
    </>
  );
};


