// import { user } from "../utils/User";
import { handleError } from "./apiUtils";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useNotification } from "../components/notificationContext";
import { useMsal } from "@azure/msal-react";
import { recordsApiConfig } from "../authConfig";
import { v4 as uuidv4 } from "uuid";
import { replaceAttributesInUrl } from "./apiUtils";

export const useDelete = (tokenizedURL, ...args) => {
  const { instance, inProgress, accounts } = useMsal();
  const { notifications, setNotifications } = useNotification();
  const accessTokenRequest = {
    ...recordsApiConfig,
    account: accounts[0],
  };

  return useMutation(async (data) => {
    try {
      const { accessToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      const url = replaceAttributesInUrl(tokenizedURL, data);
      return await axios.delete(
        `${process.env.REACT_APP_RECORD_API_URL}${url}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
    } catch (e) {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(accessTokenRequest);
      }
      var errorText = (errorText = e?.response?.data?.Failed ?? e?.message);

      if (e?.message === "Network Error")
        errorText = `Network Error, can't connect.`;
      else if (e?.response?.status === 403) {
        errorText =
          "Forbidden access. You don't have permission to see this item.";
      }
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          text: errorText,
          style: "error",
        },
      ]);
      handleError(e, instance);
    }
  }, ...args);
};
