import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useCreateGiftAid } from "../../../services/awardMgmgtService";
import { GiftAidForm } from "./GiftAidForm";
import ValidationSummary from "../../../components/ValidationSummary";
import ApiStatus from "../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb";

export const GiftAidNew = (props) => {
  const { giftSourceId } = useParams();
  const createEventMutation = useCreateGiftAid();
  const data = {    
    createdAt: new Date(new Date().getTime() + 1 * 60000),
  };

  return (
    <>
     <MyBreadcrumb breadcrumbItems={[          
            { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources` },
            { id: "giftaids", text: "Gift Aids", url: `/awardmgmt/giftsources/GS-${giftSourceId}/giftaids` },            
            { id: "new", text: "New" }  
          ]} 
        />
      <GiftAidForm
        data={data}
        eventMutation={createEventMutation}
        isNew={true}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      />
    </>
  );
};
