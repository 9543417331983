import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useFetchDepartment, useUpdateDepartment } from "../../../services/awardMgmgtService";
import { DepartmentForm } from "./DepartmentForm";
import ValidationSummary from "../../../components/ValidationSummary";
import ApiStatus from "../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

export const DepartmentEdit = (props) => {
  const { id, stepId } = useParams();
  if (!id) throw Error("Trigger Event id not found");

  const eventMutation = useUpdateDepartment(id);
  const { data: data, status : status, isSuccess : isSuccess, isError : isError, error: error } = useFetchDepartment(id);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;      

  return (
    <>
      <DepartmentForm
        data={data}
        eventMutation={eventMutation}
        isNew={false}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      />
    </>
  );
};
