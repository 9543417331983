import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "./form-components";
import { requiredValidator, fieldValidator } from "./validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "./ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "./ApiStatus";
import { PageLoader } from "./PageLoader";
import { PageMutating } from "./PageMutating";
import DOMPurify from 'dompurify';
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { useFetchEditor, useUpdateEditor  } from "../services/settingsService";
import { RolesAuthRoute } from "./RolesAuthRoute";

const {  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  NumberedList,
  BulletedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  InsertFile,
  SelectAll,
  Print,
  Pdf,
  TableProperties,
  TableCellProperties,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell } = EditorTools;


  export const CommentComponent = ({editorId, editRoles, editClaims}) => {  
    //const settingId = 'RecordsWebsiteSetting'
    const handleSelect = (e) => {            
      if(e.item?.text)
        setMode(e.item?.text);
    }

    const { data, status, isSuccess, isError, error } = useFetchEditor(editorId);
    const [mode , setMode] = React.useState("View");
    const updateMutation = useUpdateEditor(editorId, () => setMode("View"));
    
  
    if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
    if (!data) return <div> Not found.</div>;
    
    return (
      <>
      <div className="editor-container">
        <div className="gear-menu">
          <RolesAuthRoute claims={editClaims} roles={editRoles}>
            <Menu style={{maxWidth:80, float:'right'}} openOnClick={true} onSelect={handleSelect} >
              <MenuItem text="" icon="gear"  >
                <MenuItem text="Edit" />            
              </MenuItem>                
            </Menu>     
          </RolesAuthRoute>
        </div>       
          {/* { mode === "View" && <CommentComponentView data={data} /> } */}
        <RolesAuthRoute claims={editClaims} roles={editRoles}>
          { <CommentComponentForm data={data} eventMutation={updateMutation} setMode={setMode} />}
        </RolesAuthRoute>
      </div>   
      </>
    );
  };

export const CommentComponentForm = ({ data, eventMutation, setMode }) => {      

  const {isLoading } = eventMutation;   
  const editor = React.createRef();
  
  const handChange = (e, formRenderProps) => {    
    formRenderProps.onChange("htmlContent", { value: EditorUtils.getHtml(editor.current.view.state) })
  }

  return (
    <>        
    <Form
      onSubmit={(d) => 
        {                                       
            eventMutation.mutate(
            {...d , 
              htmlContent: EditorUtils.getHtml(editor.current.view.state),
               ModifiedAt: (DateTime.fromJSDate(d.availabilityEnds)).toISO(),                             
            })             
        }}      
      initialValues={data}                        
      render={(formRenderProps) => (
        <FormElement
          // style={{
          //   maxWidth: '100%',
          // }}
        >          
          <fieldset className={"k-form-fieldset"}>           
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                                                                                        
            <Editor
                tools={[]}
                name={"htmlContent"} 
                //data={ data?.visibleInfo && EditorUtils.setHtml(view, textarea.current.value)}
                defaultContent={data?.htmlContent}
                onChange={(e) => handChange(e, formRenderProps)}
                contentStyle={{
                  height: 160,
                }}
                //defaultContent={"<p>editor sample html</p>"}
                ref={editor}    />            
          </fieldset>                    
          <div style={{marginBottom:15, marginTop:10}}>
            <button
              type={"submit"}
              className="k-button k-button-lg k-rounded-lg k-button-solid k-button-solid-base"
              disabled={!formRenderProps.allowSubmit || isLoading}
              onClick={(e) => { 
                debugger;
                e.preventDefault();  
                 eventMutation.mutate(
                {...data , 
                  htmlContent: EditorUtils.getHtml(editor.current.view.state),
                   ModifiedAt: (DateTime.fromJSDate(data.availabilityEnds)).toISO(),                             
                })}}
            >            
              <span className="k-icon"><svg viewBox="0 0 512 512"><path d="M469.783 271.879 54.329 446.734c-13.149 5.534-26.266-8.042-21.225-21.967l48.3-133.404c2.16-5.966 7.298-10.169 13.326-10.901C312.468 256 239.85 263.839 312.468 256c-72.618-7.839 0 0-217.739-24.462-6.027-.732-11.165-4.935-13.325-10.901l-48.3-133.404C28.063 73.308 41.18 59.732 54.33 65.266l415.454 174.855c13.623 5.734 13.623 26.024 0 31.758"></path></svg></span>   
              Post 
            </button>                       
           <PageMutating/>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

export const CommentComponentView = ({ data }) => {          
  return (
    <>             
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.htmlContent) }} /> 
    </>
  );
};


