import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useFetch, useFetchWithPost } from "./useFetch";
import { usePut } from "./usePut";
import { usePost } from "./usePost";
import PropTypes from "prop-types";
import { useNotification } from "../components/notificationContext";
import { v4 as uuidv4 } from "uuid";
import { useDelete } from "./useDelete";
import { redirect } from "react-router-dom";

export const giftSourceTypes = [
  { id: "EndowmentFoundation", text: "Endowment Foundation" },
  { id: "EndowmentRegentalPrincipal", text: "Endowment Regental Principal" },
  { id: "EndowmentRegentalIncome", text: "Endowment Regental Income" },
  {
    id: "CurrentExpenditureFoundation",
    text: "Current Expenditure Foundation",
  },
  { id: "CurrentExpenditureRegental", text: "Current Expenditure Regental" },
  { id: "EndowmentFoundationQuasi", text: "Endowment Foundation Quasi" },
  { id: "InsitutionalLaw", text: "Insitutional Law" },
  { id: "InstitutionalCampus", text: "Institutional Campus" },
  { id: "Others", text: "Others" },
];

export const giftAidTypes = [
  { id: "DonorScholarship", text: "Donor Scholarship" },
  { id: "LawSchoolScholarship", text: "Law School Scholarship" },
  { id: "SummerFellowship", text: "Summer Fellowship" },
  { id: "Prize", text: "Prize" },
  { id: "DepartmentAward", text: "Department Award" },
  { id: "OtherGraduateFunding", text: "OtherGraduate Funding" },
];

export const useFetchDepartments = (query) => {
  return useFetch({
    url: `awardmgmt/departments`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return { ...r, createdAt: new Date(r.createdAt) };
        }),
      };
    },
    query: query,
  });
};

export const useFetchDepartment = (termId) => {
  return useFetch({
    url: `awardmgmt/departments/${termId}`,
    dataMapper: (d) => d,
  });
};

export const useCreateDepartment = () => {
  const nav = useNavigate();
  return usePost(`awardmgmt/departments`, {
    onSuccess: (_, record) => {
      nav(`/awardmgmt/departments`);
      return _;
    },
    onprogress: (_, record) => {},
  });
};

export const useDeleteDepartment = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`awardmgmt/departments/{id}`, {
    onSuccess: (_, data) => {
      queryClient.removeQueries([`awardmgmt/departments/${data.id}`]);
      queryClient.invalidateQueries(["awardmgmt/departments"]);
      //queryClient.removeQueries(["firstyearpreference"]);
      nav(`/awardmgmt/departments`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useUpdateDepartment = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`awardmgmt/departments/${id}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries(["awardmgmt/departments"]);
      queryClient.invalidateQueries([`awardmgmt/departments/${id}`]);
      nav(`/awardmgmt/departments`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useFetchCostCenters = (query) => {
  return useFetch({
    url: `awardmgmt/costcenters`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return { ...r, id: r.code, createdAt: new Date(r.createdAt) };
        }),
      };
    },
    query: query,
  });
};

export const useFetchCostCenter = (id) => {
  return useFetch({
    url: `awardmgmt/costcenters/${id}`,
    dataMapper: (d) => d,
  });
};

export const useCreateCostCenter = () => {
  const nav = useNavigate();
  return usePost(`awardmgmt/costcenters`, {
    onSuccess: (_, record) => {
      nav(`/awardmgmt/costcenters`);
      return _;
    },
    onprogress: (_, record) => {},
  });
};

export const useUpdateCostCenter = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`awardmgmt/costcenters/${id}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries(["awardmgmt/costcenters"]);
      queryClient.invalidateQueries([`awardmgmt/costcenters/${id}`]);
      nav(`/awardmgmt/costcenters`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      debugger;
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useDeleteCostCenter = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`awardmgmt/costcenters/{id}`, {
    onSuccess: (_, data) => {
      queryClient.removeQueries([`awardmgmt/costcenters/${data.id}`]);
      queryClient.invalidateQueries(["awardmgmt/costcenters"]);
      //queryClient.removeQueries(["firstyearpreference"]);
      nav(`/awardmgmt/costcenters`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useFetchGiftSources = (query) => {
  return useFetch({
    url: `awardmgmt/giftsources`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return {
            ...r,
            createdAt: new Date(r.createdAt),
          };
        }),
      };
    },
    query: query,
  });
};

export const useFetchGiftSource = (id) => {
  return useFetch({
    url: `awardmgmt/giftsources/${id}`,
    dataMapper: (d) => {
      return {
        ...d,
        dateEstablished: new Date(d.dateEstablished),
        type: {
          id: d.type,
          text: giftSourceTypes.find((i) => i.id === d.type).text,
        },
        departments: d.departments.split(";").map((id) => ({
          id: id,
          name: id,
        })),
      };
    },
  });
};

export const useCreateGiftSource = () => {
  const nav = useNavigate();
  return usePost(`awardmgmt/giftsources`, {
    onSuccess: (_, record) => {
      nav(`/awardmgmt/giftsources`);
      return _;
    },
    onprogress: (_, record) => {},
  });
};

export const useUpdateGiftSource = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`awardmgmt/giftsources/${id}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries(["awardmgmt/giftsources"]);
      queryClient.invalidateQueries([`awardmgmt/giftsources/${id}`]);
      nav(`/awardmgmt/giftsources`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      debugger;
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useDeleteGiftSource = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`awardmgmt/giftsources/{id}`, {
    onSuccess: (_, data) => {
      queryClient.removeQueries([`awardmgmt/giftsources/${data.id}`]);
      queryClient.invalidateQueries(["awardmgmt/giftsources"]);
      //queryClient.removeQueries(["firstyearpreference"]);
      nav(`/awardmgmt/giftsources`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useFetchGiftAids = (query) => {
  return useFetch({
    url: `awardmgmt/giftaids`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return {
            ...r,
            createdAt: new Date(r.createdAt),
          };
        }),
      };
    },
    query: query,
  });
};

export const useFetchDepartmentGiftAids = (query, dataState) => {
  //debugger;
  return useFetch({
    url: `awardmgmt/departments/${dataState?.departmentId}/giftaids`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return {
            ...r,
            value: r.key,
          };
        }),
      };
    },
    query: query,
  });
};

export const useFetchGiftAid = (id) => {
  return useFetch({
    url: `awardmgmt/giftaids/${id}`,
    dataMapper: (d) => {
      return {
        ...d,
        dateEstablished: new Date(d.dateEstablished),
        type: {
          id: d.type,
          text: giftAidTypes.find((i) => i.id === d.type).text,
        },
        // departments: d.departments.map((id) => ({
        //   id: id,
        //   name: id,
        // })),
      };
    },
  });
};

export const useCreateGiftAid = () => {
  const nav = useNavigate();
  const queryClient = useQueryClient();
  return usePost(`awardmgmt/giftaids`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries([
        `/awardmgmt/giftsources/GS-${record?.giftSourceId}/giftaids`,
      ]);
      nav(`/awardmgmt/giftsources/GS-${record?.giftSourceId}/giftaids`);
      return _;
    },
    onprogress: (_, record) => {},
  });
};

export const useUpdateGiftAid = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`awardmgmt/giftaids/${id}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries([
        `awardmgmt/giftsources/${record?.giftSourceId}/giftaids`,
      ]);
      queryClient.invalidateQueries([`awardmgmt/giftaids/${id}`]);
      nav(`/awardmgmt/giftsources/GS-${record?.giftSourceId}/giftaids`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      debugger;
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useDeleteGiftAid = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`awardmgmt/giftaids/{id}`, {
    onSuccess: (_, data) => {
      queryClient.removeQueries([`awardmgmt/giftaids/${data.id}`]);
      queryClient.invalidateQueries(["awardmgmt/giftaids"]);
      //queryClient.removeQueries(["firstyearpreference"]);
      nav(`/awardmgmt/giftsources/GS-${data.giftSourceId}/giftaids`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useFetchProjections = (query) => {
  return useFetch({
    url: `awardmgmt/projections`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return {
            ...r,
            createdAt: new Date(r.createdAt),
          };
        }),
      };
    },
    query: query,
  });
};

export const useFetchProjection = (giftAidId, year) => {
  return useFetch({
    url: `awardmgmt/projections/${giftAidId}/${year}`,
    dataMapper: (d) => {
      return {
        ...d,
        year: new Date(d.year),
        type: {
          id: d.type,
          text: giftAidTypes.find((i) => i.id === d.type).text,
        },
        // departments: d.departments.map((id) => ({
        //   id: id,
        //   name: id,
        // })),
      };
    },
  });
};

export const useCreateProjection = () => {
  const nav = useNavigate();
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  return usePost(`awardmgmt/projections`);
};

export const useUpdateProjection = (giftAidId) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`awardmgmt/projections/${giftAidId}/{year}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries([
        `/awardmgmt/giftaids/${record?.giftAidId}/projections`,
      ]);
      //nav(`/awardmgmt/giftsources/GS-${record?.giftSourceId}/giftaids`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      debugger;
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useDeleteProjection = (projection) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`awardmgmt/projections/{giftAidId}/{year}`, {
    onSuccess: (_, data) => {
      queryClient.removeQueries([
        `awardmgmt/projections/${data.giftAidId}/${data.year}`,
      ]);
      queryClient.invalidateQueries(["awardmgmt/projections"]);
      //queryClient.removeQueries(["firstyearpreference"]);
      //nav(`/awardmgmt/giftaids/${data.giftAidId}/projections`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useFetchAwards = (query) => {
  return useFetch({
    url: `awardmgmt/awards`,
    dataMapper: (d) => {
      return {
        ...d,
        data: d.data.map((r) => {
          return {
            ...r,
            department: { ...r.department, text: r.department?.name },
            mtfRequestCompletedAt:
              r.mtfRequestCompletedAt == null
                ? null
                : new Date(r.mtfRequestCompletedAt),
            createdAt: new Date(r.createdAt),
          };
        }),
        offeredAmountSum: d.data.reduce((acc, r) => acc + r.offeredAmount, 0),
        processedAmountSum: d.data.reduce(
          (acc, r) => acc + r.processedAmount,
          0
        ),
      };
    },
    query: query,
  });
};

export const useFetchAward = (id) => {
  return useFetch({
    url: `awardmgmt/awards/${id}`,
    dataMapper: (d) => {
      return {
        ...d,
        dateEstablished: new Date(d.dateEstablished),
        type: {
          id: d.type,
          text: giftAidTypes.find((i) => i.id === d.type).text,
        },
        // departments: d.departments.map((id) => ({
        //   id: id,
        //   name: id,
        // })),
      };
    },
  });
};

export const useCreateAward = () => {
  return usePost(`awardmgmt/awards`);
};

export const useUpdateAward = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`awardmgmt/awards/{id}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries(["awardmgmt/awards"]);
      queryClient.invalidateQueries([`awardmgmt/awards/${record?.id}`]);
      //nav(`/awardmgmt/awards`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      debugger;
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "error",
          text: `Failed to save the changes. ${Object.values(
            _?.response?.data
          )?.join(",")}`,
        },
      ]);
    },
  });
};

export const useDeleteAward = () => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useDelete(`awardmgmt/awards/{id}`, {
    onSuccess: (_, data) => {
      queryClient.removeQueries([`awardmgmt/awards/${data.id}`]);
      queryClient.invalidateQueries(["awardmgmt/awards"]);
      //queryClient.removeQueries(["firstyearpreference"]);
      //nav(`/awardmgmt/awards`);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useFetchAwardSpreadsheet = (departmentId) => {
  return useFetch({
    url: `spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${departmentId}`,
    dataMapper: (d) => {
      // return {
      //   ...d,
      //   availabilityStarts: new Date(d.availabilityStarts),
      //   availabilityEnds: new Date(d.availabilityEnds),
      // };
      return d;
    },
  });
};

export const useUpdateAwardSpreadsheet = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(`spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${id}`, {
    onSuccess: (_, record) => {
      queryClient.invalidateQueries([
        `spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${id}`,
      ]);
      setNotifications([
        ...notifications,
        {
          id: uuidv4(),
          style: "success",
          text: `Your changes has been saved.`,
        },
      ]);
    },
    onError: (_, e) => {
      //debugger;
      //console.log(_);
      //console.log(e);
    },
  });
};

export const useValidateAwardSpreadsheet = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(
    `spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${id}/validate`,
    {
      onSuccess: (_, record) => {
        queryClient.invalidateQueries([
          `spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${id}`,
        ]);
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            style: "success",
            text: `Validation done.`,
          },
        ]);
      },
      onError: (_, e) => {
        //debugger;
        //console.log(_);
        //console.log(e);
      },
    }
  );
};

export const useProcessAwardSpreadsheet = (id) => {
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const nav = useNavigate();

  return usePut(
    `spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${id}/process`,
    {
      onSuccess: (_, record) => {
        queryClient.invalidateQueries([
          `spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${id}`,
        ]);
        queryClient.invalidateQueries([`awardmgmt/departments/${id}/giftaids`]);
        queryClient.invalidateQueries([`awardmgmt/awards`]);
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            style: "success",
            text: `Your changes has been saved.`,
          },
        ]);
      },
      onError: (_, e) => {
        queryClient.invalidateQueries([
          `spreadsheet/awardmgmt/DepartmentsAwardsSpreadsheet/${id}`,
        ]);
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            style: "error",
            text: `Failed to process: ${Object.values(_?.response?.data)?.join(
              ","
            )}`,
          },
        ]);
        //debugger;
        //console.log(_);
        //console.log(e);
      },
    }
  );
};
