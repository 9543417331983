import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../../components/form-components.jsx";
import { requiredValidator, fieldValidator } from "../../../components/validators.jsx";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../../components/ValidationSummary.jsx";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../../components/ApiStatus.js";
import { PageLoader } from "../../../components/PageLoader.jsx";
import { PageMutating } from "../../../components/PageMutating.jsx";
import { DateTime, Settings } from "luxon";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import {  
  Hint  
} from "@progress/kendo-react-labels";
import { giftSourceTypes } from "../../../services/awardMgmgtService.js";
import {useFetchDepartments} from "../../../services/awardMgmgtService.js";

const ValidatedInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};


export const GiftSourceForm = ({ data, eventMutation, isNew = false }) => {      

  const {isLoading } = eventMutation; 
  const [type, setType] = React.useState(data?.type?.id);

  const { data: departments, status : departmentsStatus, isSuccess : departmentsIsSuccess, isError : departmentsisError, error: departmentsError } = useFetchDepartments();  

  if (!departmentsIsSuccess) return <ApiStatus status={departmentsStatus}></ApiStatus>;
  if (!departments) return <div> Not found.</div>;      

  data.departments = data?.departments?.map((d) => ({
    id: d.id,
    name: departments?.data?.find((i) => i.id === d.id)?.name ?? d.id,
  }));

  const fieldChange = (e) =>
  {            
    const fieldName = e.target.name;    
    if(fieldName === "type")
    setType(e.value.id);
    if(eventMutation.error?.response?.data == null)        
    return;
    delete eventMutation.error.response.data[fieldName];    
  }  
  
  return (
    <>        
    <Form
      onSubmit={(d) => 
        {          
          return eventMutation.mutate( 
            {...d , 
              type: d?.type?.id,              
              departments: d?.departments?.map((d) => d.id)?.join(";"),
              modifiedAt:  (DateTime.fromJSDate(new Date())).toISO(),               
      })}}
      //onSubmit={(d) => console.log(d)}
      //validator={(v) => triggerEventFormValidator(v,triggerEventMutation.isError, triggerEventMutation.error) }
      initialValues={data}                        
      render={(formRenderProps) => (
        <FormElement
          // style={{
          //   maxWidth: 650,
          // }}
        >
          {/* <p> hi {data?.triggeredBy}</p> */}
          <fieldset className={"k-form-fieldset"}>
            {/* <legend className={"k-form-legend"}>
              Please fill in the following information:
            </legend> */}
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}    
              <div className="container">
                <div className="row">                                
                  <div className="col">
                    <Field 
                      name={"name"} 
                      component={ValidatedInput} 
                      onChange={fieldChange}                            
                      label={"Name"} 
                    />
                    <Field 
                      name={"formalName"} 
                      component={ValidatedInput} 
                      onChange={fieldChange}                            
                      label={"Formal Name"} 
                    />             
                    <div className="field">
                      <Field 
                        name={"type"} 
                        component={DropDownList} 
                        data={giftSourceTypes}
                        onChange={fieldChange}                                     
                        textField="text"
                        dataItemKey="id"
                        label={"Type"} 
                        validator={(d) => fieldValidator(d,"type", eventMutation.error, requiredValidator)} 
                      />                      
                    </div>

                    <Field 
                      name={"foundationNumber"} 
                      component={NumericTextBox} 
                      format="0"
                      spinners={false}
                      onChange={fieldChange}                                     
                      label={"Foundation Number"} 
                    /> 

                    <Field 
                      name={"fundNumber"} 
                      component={NumericTextBox} 
                      format="0"
                      spinners={false}
                      onChange={fieldChange}                                     
                      label={"Fund Number"} 
                    />
                    <Field 
                      name={"amount"} 
                      component={NumericTextBox} 
                      onChange={fieldChange}                                     
                      label={"Amount"} 
                    />                       

                    <div className="field">
                      <Field                
                        name={"dateEstablished"}
                        label={"Date Established"}
                        hintDirection={"end"}                
                        component={DatePicker}
                        onChange={fieldChange}                  
                        validator={(d) => fieldValidator(d,"dateEstablished", eventMutation.error)} 
                        //format="dd-MMM-yyyy HH:mm Z"
                      />                
                    </div>
                    <Field 
                      name={"sourceDocumentURL"} 
                      component={ValidatedInput} 
                      onChange={fieldChange}                                     
                      label={"Source Document URL"} 
                    />   
                  </div>                                                      
                  <div className="col">        
                  <Field 
                        name={"departments"} 
                        component={MultiSelect} 
                        data={departments?.data}
                        onChange={fieldChange}                                     
                        textField="name"
                        dataItemKey="id"
                        label={"Departments"} //
                        validator={(d) => fieldValidator(d,"departments", eventMutation.error, requiredValidator)} 
                      />                                                                                        
                              
                    <div className="field">
                      <label>Description</label>
                      <Field 
                        name={"description"} 
                        component={TextArea} 
                        onChange={fieldChange}                                     
                        label={"Description"} 
                      />    
                    </div>
                    <div className="field">
                      <label>Criteria</label>
                      <Field 
                        name={"criteria"} 
                        component={TextArea} 
                        onChange={fieldChange}                                     
                        label={"Criteria"} 
                      />    
                    </div>
                    <div className="field">
                      <label>Admin Notes</label>
                      <Field 
                        name={"adminNotes"} 
                        component={TextArea} 
                        onChange={fieldChange}                                     
                        label={"AdminNotes"} 
                      />    
                    </div>                  
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="k-form-buttons">
                        <button
                          type={"submit"}
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                          disabled={!formRenderProps.allowSubmit || isLoading}
                        >              
                          Submit
                        </button>            
                      <PageMutating/>
                      </div>
                  </div>
                  
                </div>
              </div>             
          </fieldset>
         
        </FormElement>        
      )}
    />
    <style>
          {`
          .k-form-fieldset div.field
          {
            margin-top: 10px;
          }
          .k-form-fieldset .k-floating-label-container{
            min-height: 70px;
          }
          `}
        </style>
    </>
  );
};

