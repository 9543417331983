//import './CourseEvaluations.css';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { DateTime, Settings } from "luxon";
import {useFetchCostCenters, useDeleteCostCenter} from "../../../services/awardMgmgtService.js";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { DeleteCommandCell, ViewCommandCell, EditCommandCell, filterOperators } from "../../../components/GridComponents.jsx";

const StatefullGrid = WithState(Grid);

export const CostCentersGrid = () => {  
  const deleteMutation = useDeleteCostCenter();    

  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "name", dir: "asc" }
    ],
    skip: 0,
    take: 10,
  });

  const [gridData, setGridData] = React.useState([]);  
  
  const navigate = useNavigate();

  const RecordCombinedCommandCell = (props) => (
    <td className="record-combined-command-cell">
      {/* <ViewCommandCell {...props} enterView={enterView}  /> */}
      <EditCommandCell {...props} enterEdit={enterEdit} />      
      <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
    </td>
  );

  const enterView = (item) => {
    navigate(`${item.id}`);
  };

  const enterEdit = (item) => {     
    navigate(`${item.code}/edit`);
  }  

  return (
    <>      
       <div style={{margin:'10px 0px'}}>
        <button className="btn btn-primary"  onClick={() => navigate('new') }> New </button>      
        {/* <Link className="btn btn-primary" style={{marginLeft:'10px'}} role="button" to="courses?sort=srsNumber-asc&page=1&pageSize=10"> Course </Link>       */}
        {/* <Link className="btn btn-primary" style={{marginLeft:'10px'}} role="button" to="enrollments"> Enrollments </Link>       */}
      </div>    
      <StatefullGrid
        apiDataSource={useFetchCostCenters}
        filterOperators={filterOperators}
        sortable={true}
        pageable={true}
        resizable={true}
        // data={d => d.map((item) => ({
        //   ...item,
        //   inEdit: item.srsNumber === editID,
        // }))}
        minResizableWidth={30}                        
        dataState={gridDataState}
        gridData={gridData}
        setGridDataState={setGridDataState}
        setGridData={setGridData}
        updateURLSearchParams = {true}        
      > 
      <GridColumn field="code" title="Code" filterable={true} />                               
        <GridColumn field="name" title="Name" filterable={true} />                        
        <GridColumn field="description" title="Description" filterable={true} />                                                            
        <GridColumn cell={RecordCombinedCommandCell} filterable={false} width={"180px"} />
      </StatefullGrid>      
    </>
  );
};
