//import './CourseEvaluations.css';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSearchParams, useParams} from "react-router-dom";
import { DateTime, Settings } from "luxon";
import { useCreateAward, useCreateProjection, useDeleteAward, useDeleteProjection, useFetchAward, useFetchAwards, useFetchDepartment, useFetchDepartments, useFetchProjections, useFetchProjectionsForGiftAid, useUpdateAward, useUpdateProjection} from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, EditCommandCell, ViewCommandCell, filterOperators } from "../../../components/GridComponents.jsx";
import { MyCommandCell } from "../components/MyCommandCell.jsx";
import { Button } from '@progress/kendo-react-buttons';
import { useNotification } from "../../../components/notificationContext.js"
import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from 'uuid';
import { DropDownCell } from "../components/DropDownCell.jsx";
import ApiStatus from "../../../components/ApiStatus.js";
import { IntlService } from '@progress/kendo-react-intl';

const editField = "inEdit";
const CommandCell = (props) => {
  const { edit, remove, add, discard, update, cancel, editField } = props;
  return (
    <MyCommandCell
      {...props}
      edit={edit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );
};

const StatefullGrid = WithState(Grid);

const breadcrumbItems = [{
  id: "gift_sources",
  text: " Gift Sources",
  URL: "/gift_sources",
  iconClass: 'k-i-cart'
}, 
{
  id: "gift_aids",
  text: "Gift Aids"
}];

export const AwardsGrid = ({ departmentId }) => {
  //const { giftSourceId, giftAidId } = useParams();

  const [editID, setEditID] = React.useState(null);
  const { data: departments, status : departmentsStatus, isSuccess : departmentsIsSuccess, isError : departmentsisError, error: departmentsError } = useFetchDepartments();  
  const createMutation = useCreateAward();
  const deleteMutation = useDeleteAward();    
  const updateMutation = useUpdateAward();
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const intl = new IntlService('en-US'); 
  
  

  const rowClick = (event) => {
    //debugger;
    setEditID(event.dataItem.year);
  };
  
  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "id", dir: "desc" }
    ],
    filter: {
      logic: 'and',
      filters: [
        { field: 'departmentId', operator: 'eq', value: departmentId }
      ]
    },
    skip: 0,
    take: 11,
  });

  
  const [gridData, setGridData] = React.useState([]);
  const [gridDataOrig, setGridDataOrig] = React.useState([]);

  const navigate = useNavigate();

  if (!departmentsIsSuccess) return <ApiStatus status={departmentsStatus}></ApiStatus>;
  if (!departments) return <div> Not found.</div>;      

   // modify the data in the store, db etc
   const remove = (dataItem) => {
  const newData = [...deleteMutation.mutate(dataItem)];
    
    // setGridData(
    //   {...gridData, data:gridData.data.map(
    //     item => getId(dataItem.giftAidId,dataItem.year) ? { ...item, inEdit: false} : item
    //   )
    //   }    
    // );
  };
  const add = (dataItem) => {
    dataItem.inEdit = true;
    const newData = createMutation.mutate(dataItem, {
      onSuccess: (_, record) => {
        queryClient.invalidateQueries(["awardmgmt/awards"]);        
        
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            style: "success",
            text: `Your changes has been saved.`,
          },
        ]);
        
        // setGridData(
        //   {...gridData, data:gridData.data.map(
        //     item => dataItem.giftAidId + dataItem.year ? { ...item, inEdit: false} : item
        //   )
        //   }    
        // );
        //gridDataOrig[getId(dataItem)] = dataItem;
        return _;
      },
      onError: (_, d) => {
        //debugger;
        
        var errorText = (errorText = _?.response?.data?.Failed ?? _?.message);
        
        // if e?.response?.data?.errors is not empty then add the errors to the errorText        
        if (_?.response?.data?.errors) {
          errorText = Object.values(_?.response?.data?.errors).join(" ");
        }

        if (_?.message === "Network Error")
          errorText = `Network Error, can't connect.`;
        else if (_?.response?.status === 403) {
          errorText =
            "Forbidden access. You don't have permission to see this item.";
        }
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            text: errorText,
            style: "error",
          },
        ]);        
      }
    });  
  };

  const update = async (dataItem) => {

    const res = await updateMutation.mutateAsync({...dataItem, modifiedAt:  (DateTime.fromJSDate(new Date())).toISO()});
    // dataItem.inEdit = false;
    // const newData = updateItem(dataItem);
    // setData(newData);
    setGridData(
      {...gridData, data:gridData.data.map(
        item => getId(dataItem) ? { ...item, inEdit: false} : item
      )
      }    
    );
    gridDataOrig[getId(dataItem)] = dataItem;
  };

  const discard = () => {
    const newData = [...gridData.data];
    newData.splice(0, 1);    
    setGridData(
      {...gridData, data:newData
      }    
    );   
  };

  const getId = (dataItem) => dataItem.id;

  const cancel = (dataItem) => {    
    var origItem = gridDataOrig[getId(dataItem)];
    setGridData(
      {...gridData, data:gridData.data.map(
        item => item.id === dataItem.id ? { ...origItem, inEdit: false} : item
      )
      }    
    );       
  };

  // const enterView = (item) => {
  //   navigate(`GA-${item.id}`);
  // };

  const enterEdit = (dataItem) => {     
    setGridData(
      {...gridData, data:gridData.data.map(
        item => item.id === dataItem.id  ? {...item, inEdit: true} : item
      )
      }    
    );
    const idToRow = gridData.data.reduce((acc, item, index) => {;
      acc[getId(item)] = item;
      return acc;
    }, {});
    setGridDataOrig(idToRow); 
  }  

  const itemChange = (event) => {    
    const newData = {...gridData, data:gridData.data.map(
      item => item.id === event.dataItem.id ? {...item, [event.field || ""]: event.value} : item
    )}    
    setGridData(newData);
  };

  const addNew = () => {
    const newDataItem = {
      id : uuidv4(),
      //giftAidId: Number(giftAidId), // check if this is correct
      year: new Date().getFullYear(),
      departmentId: departmentId, // check if this is correct
      amount: 0,
      inEdit: true,   
      isNew: true,
      createdAt: (DateTime.fromJSDate(new Date())).toISO(),
      modifiedAt: (DateTime.fromJSDate(new Date())).toISO(),
      //yearOptions : yearsOptions(20,23),
      //departmentOptions : departments.data.map(dept => ({ id: dept.id, text: dept.name })),
    };

    setGridData(
      {...gridData, data:[newDataItem, ...gridData.data]
      }    
    );    
  };
  
  const commandCellProps = {
    edit: enterEdit,
    remove: remove,
    add: add,
    discard: discard,
    update: update,
    cancel: cancel,
    editField: editField,
  };

  const yearsOptions = (yearsBack, count) => {
    const startYear = new Date().getFullYear() - yearsBack;
    //const existingYears = new Set(gridData.data?.map(item => item.year));

  return Array.from({ length: count }, (_, i) => startYear + i)
    //.filter(year => !existingYears.has(year))
    .map(year => ({ id: year, text: year }))
    .sort((a, b) => b.id - a.id);
  };

  const termOptions = [{ id: "Fall", text: "Fall" }, { id: "Spring", text: "Spring" }, { id: "Summer", text: "Summer" }];
  //  Offered, Revised, Processed
  const statusOptions = [{ id: "Offered", text: "Offered" }, { id: "Revised", text: "Revised" }, { id: "Processed", text: "Processed" }];

  const rowRender = (row, props) => {
    const { dataItem } = props;
    // If price < 300, add a red-row class
    const className =  dataItem.offeredAmount !== dataItem.processedAmount ? 'warning' : '';
    
    return React.cloneElement(
      row,
      { ...row.props, className: `${row.props.className} ${className}` },
      row.props.children
    );      
  };

  return (
    <>           
      <div className="row" style={{width:'100%'}}>        
        <h5>Awards</h5>        
      </div>               
      <StatefullGrid      
        apiDataSource={useFetchAwards}      
        filterOperators={filterOperators}
        sortable={true}
        pageable={true}
        rowRender={rowRender}
        resizable={true}
        // data={d => d.map((item) => ({
        //   ...item,
        //   inEdit: item.srsNumber === editID,
        // }))}
        minResizableWidth={30}          
        onItemChange={itemChange}
        editField="inEdit"
        onRowClick={rowClick}

        dataState={gridDataState}
        gridData={gridData}
        setGridDataState={setGridDataState}
        setGridData={setGridData}
        updateURLSearchParams = {false}        
      >         
        <GridToolbar>       
          <Button title="Add new" icon="plus" themeColor={'primary'} onClick={addNew} type="button">
              Award
          </Button>
        </GridToolbar>         
        <GridColumn field="prefixedId" title="Award Id"  filterable={false} editable={false} width={100}  />                                                
        <GridColumn field="giftAid.prefixedId" title="GiftAid Id"   filterable={false} width={100} />             
        {/* <GridColumn field="departmentId" title="Department" cells={ departments?.data?.map(dept => ({ id: dept.id, text: dept.name })) }  cell={DropDownCell}  filterable={false} width={"250px"}  />           */}
        <GridColumn field="studentUID" title="Student UID"  filterable={false} width={"130px"}   />                                                
        <GridColumn field="year" title="Year" cells={ yearsOptions(20,23)}  editor="numeric" cell={DropDownCell} options="test" filterable={false}  width={"120px"}  />    
        <GridColumn field="term" title="Term" cells={ termOptions.map(m => m) }  cell={DropDownCell} filterable={false}  width={130}  />            
        
        <GridColumn field="offeredAmount" title="Offered Amount"  editor="numeric" format="{0:c}" footerCell={props => (
            <td className='k-table-td' colSpan={props.colSpan} style={props.style} role={'gridcell'}>Sum: {  intl.formatNumber( gridData.offeredAmountSum, 'c')} </td>
          )}   filterable={false} width={"150px"} />                     
        <GridColumn field="processedAmount" title="Processed Amount"  editor="numeric" format="{0:c}"   filterable={false} editable={false} width={"150px"} footerCell={props => (
            <td className='k-table-td' colSpan={props.colSpan} style={props.style} role={'gridcell'}>Sum: {  intl.formatNumber( gridData.processedAmountSum, 'c')} </td>
          )} />                     
        <GridColumn field="mtfRequestCompletedAt" title="MTF Request Completed At" filter="date" format="{0:MM/dd/yyyy}" editor="date" filterable={false} editable={false} width={150}  />
        {/* <GridColumn field="status" title="Status" cells={statusOptions}  cell={DropDownCell} filterable={false} editable={false}  width={130} />     */}
        {/* <GridColumn field="mTFRequestCompletedAt" title="mTFRequestCompletedAt"  filterable={false}  />     */}
        {/* <GridColumn field="modifiedBy" title="modifiedBy"   filterable={true} width={200} />              */}        
        {/* create a new column for modifiedByUser.name field */}

        <GridColumn field="modifiedByUser.email" title="Last Modified By" filterable={false}  width={170 } editable={false} />                                 
        <GridColumn field="modifiedAt" editable={false} title="Modified At" filterable={false} filter="date" format="{0:MM/dd/yyyy}" width={"100px"} />       
        {/* <GridColumn cell={RecordCombinedCommandCell} filterable={false}  width={"200px"}/> */}
        <GridColumn filterable={false}
          cell={(props) => <CommandCell {...props} {...commandCellProps} />}
          width="170px"
        />
       
      </StatefullGrid>
      <style>
        {`
        
        .warning {
            color: #9800fc;                                  
            border: 1px solid #ffeeba !important;
        }
        .warning td{                                             
            border-top: 1px solid #9800fc !important;            
            border-bottom: 1px solid #9800fc !important;            
        }
        `}
      </style>
    </>
  );
};
