import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useParams } from "react-router-dom";
import { Spreadsheet } from "@progress/kendo-react-spreadsheet";
import { Button } from "@progress/kendo-react-buttons";
import ApiStatus from "../../../components/ApiStatus";
import { useFetchSetting, FIRST_YEAR_PREFERENCES_SETTING } from "../../../services/settingsService";
//import { useUpdateSpreadsheet, useFetchEnrollmentSpreadsheet, useFetchProceessedFile, useFetchAwardSpreadsheet, useValidateAwardSpreadsheet, useUpdateAwardSpreadsheet } from "../../../services/enrollmentSpreadsheetService";
import { useFetchAwardSpreadsheet, useValidateAwardSpreadsheet, useUpdateAwardSpreadsheet, useProcessAwardSpreadsheet } from "../../../services/awardMgmgtService";
//import { saveAs } from 'file-saver';
import { ToolbarSeparator } from "@progress/kendo-react-buttons";
import {
  ExcelExport,
  Open,
  Bold,
  Italic,
  Underline,
  FontFamily,
  FontSize,
  TextColor,
  BackgroundColor,
  Alignment,
  TextWrap,
  GridLines,  
  Format,
  AddColumnLeft,
  AddColumnRight,
  AddRowAbove,
  AddRowBelow,
  DeleteColumn,
  DeleteRow,
  Undo,
  Redo,
} from "@progress/kendo-react-spreadsheet";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { Icon } from '@progress/kendo-react-common';

export const AwardsSpreadsheet = ({ departmentId }) => {
  const {data: ssData, status : ssStatus, isSuccess : ssIsSuccess, isError : ssIsError, error: ssError } = useFetchAwardSpreadsheet(departmentId);

  if (!ssStatus) return <ApiStatus status={ssStatus}></ApiStatus>;
  if (!ssData) return <div> Loading...</div>;   
  return <AwardsSpreadsheetHelper data={ssData} departmentId={departmentId} />;
}

export const AwardsSpreadsheetHelper = ({ departmentId, data }) => {
  // const { data: settings, status: settingsStatus, isSuccess: settingsSuccess, isError: settingsIsError, error: settingsError } = useFetchSetting(FIRST_YEAR_PREFERENCES_SETTING);    
  // if (!settingsSuccess) return <ApiStatus status={settingsStatus}></ApiStatus>;
  const spreadsheetRef = React.useRef(null);
  //const {data: ssData, status : ssStatus, isSuccess : ssIsSuccess, isError : ssIsError, error: ssError } = useFetchEnrollmentSpreadsheet();
  const updateEventMutation = useUpdateAwardSpreadsheet(departmentId);
  const validateEventMutation = useValidateAwardSpreadsheet(departmentId);
  const processEvantMutation = useProcessAwardSpreadsheet(departmentId);
  
  const [spreadSheetData, setSpreadSheetData] = React.useState(data);

  // Whenever "data" changes, update our local state
  React.useEffect(() => {    
    setSpreadSheetData(data);
  }, [data]);

  // Then, whenever `spreadSheetData` changes, re-initialize the Spreadsheet
React.useEffect(() => {
  if (spreadsheetRef.current && spreadSheetData) {
    spreadsheetRef.current.fromJSON(spreadSheetData);
  }
}, [spreadSheetData]);


  //if (!ssStatus) return <ApiStatus status={ssStatus}></ApiStatus>;
  //if (!ssData) return <div> Loading...</div>;      

  const handleChange = (e) => {
      console.log(e);
      const updatedspreadSheetData = {...spreadSheetData};      
      
      const sheet = updatedspreadSheetData.sheets.find(s => s.name === spreadsheetRef.current.activeSheet()._sheetName);
      debugger;
      sheet.rows.forEach((rowIndex) => {
        sheet.rows[rowIndex] = { ...sheet.rows[rowIndex], 
          cells: sheet.rows[rowIndex]?.cells?.map(c => { return { ...c, filter: '@'} })
          }
        })

      // Track the highest row index before the change
      // const existingRowsCount = sheet.rows.length;
      
      // // Check for new rows being added
      // const newRows = e.affectedCells.reduce((acc, cell) => {
      //   if (cell.rowIndex >= existingRowsCount) {
      //     if (!acc.includes(cell.rowIndex)) {
      //       acc.push(cell.rowIndex);
      //     }
      //   }
      //   return acc;
      // }, []);
  
      // // Apply the custom editor only to the necessary cells in new rows
      // newRows.forEach((rowIndex) => {
      //   sheet.rows[rowIndex] = {
      //     cells: [
      //       { value: sheet.rows[rowIndex]?.cells[0]?.value || 'New Item' },
      //       { value: sheet.rows[rowIndex]?.cells[1]?.value || '', filter: '@' },
      //     ],
      //   };
      // });
  
      setSpreadSheetData(updatedspreadSheetData);
  }    
  
  const save = () => {        
    if (spreadsheetRef.current) {      
      const spreadsheet = spreadsheetRef.current;    
      const spreadsheetJson = spreadsheet.toJSON()         
      updateEventMutation.mutateAsync({...spreadsheetJson , departmentId: departmentId, });
    }
  };

  const validate = () => {    
    if (spreadsheetRef.current) {      
      const spreadsheet = spreadsheetRef.current;    
      const spreadsheetJson = spreadsheet.toJSON()         
      validateEventMutation.mutateAsync({...spreadsheetJson , departmentId: departmentId, });
    }
  };

  const process = () => {    
    if (spreadsheetRef.current) {      
      const spreadsheet = spreadsheetRef.current;    
      const spreadsheetJson = spreadsheet.toJSON()         
      processEvantMutation.mutateAsync({...spreadsheetJson , departmentId: departmentId, });
    }
  };

  const reset = () => {    
    if (spreadsheetRef.current) {      
      const spreadsheet = spreadsheetRef.current; 
      var sh = spreadsheet.activeSheet()
      //var r = sh.range("A1:Z1");
      //r.enable(false);      
      // get row lenght                 
      var range = sh.range(`A2:G${sh._rows._count}`);      
      range.values("");            
    }
  };

  const exportToExcel = () => {
    if (spreadsheetRef.current) {      
      spreadsheetRef.current.saveAsExcel({
        fileName: "exportedFileName.xlsx",
      });      
    }
  };

  
  const exportToFileJS = () => {
    if (spreadsheetRef.current) {
      debugger;
      // spreadsheet.current.saveAsExcel({
      //   fileName: "exportedFileName.xlsx",
      // });
      const spreadsheet = spreadsheetRef.current;
      const activeSheet = spreadsheet.activeSheet();
      const range = activeSheet.range("A1:B100"); // Adjust the range based on your sheet
      const values = range.values();

      let txtContent = '';
      values.forEach(row => {
        txtContent += row.join('\t') + '\n';
      });

      const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
      //saveAs(blob, 'spreadsheet-data.txt');
    }
  };

  return (
    <>      
    {/* <h1>{departmentId}</h1>    */}
        <div
        style={{
          // margin: "30px 30px 30px 30px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >          
        </div>       
      </div>   
       <Spreadsheet
          ref={spreadsheetRef}
            style={{
              width: "100%",
              height: 680,
          }}          
          toolbar={[
            // {
            //   text: "File",
            //   tools: [Open, ExcelExport],
            // },
            {
              text: "Home",
              selected: true,
              tools: [
                [Undo, Redo],
                ToolbarSeparator,
                FontFamily,
                FontSize,
                [Bold, Italic, Underline],
                TextColor,
                ToolbarSeparator,
                BackgroundColor,      
                AddColumnLeft,
                AddColumnRight,   
                DeleteColumn,       
                ToolbarSeparator,
                Alignment,
                TextWrap,
                ToolbarSeparator,
                Format,
                ToolbarSeparator,
                GridLines,
              ],
            },
            // {
            //   text: "Insert",
            //   tools: [
            //     [AddColumnLeft, AddColumnRight, AddRowBelow, AddRowAbove],
            //     ToolbarSeparator,
            //     [DeleteColumn, DeleteRow],
            //   ],
            // },
          ]}
          //onChange={handleChange}
          defaultProps={spreadSheetData}
      />
       <div
          className="example-config"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            className="k-form-field"
            style={{
              flex: "1 1 0px",
            }}
          >
            <div className="k-form-field-wrap">
              <Button id="exportBtn" onClick={save}>
                <span className="k-icon k-i-save"></span>  
                <span style={{padding:5}}>Save</span>                
              </Button>   

              <Button id="exportBtn" onClick={reset} style={ { marginLeft:20 }}>
              <span className="k-icon k-i-arrow-rotate-cw"></span>  
              <span style={{padding:5}}>Reset</span>                   
              </Button>                  

              <Button id="exportBtn" onClick={exportToExcel} style={ { marginLeft:20 }}>
              <span className="k-icon k-i-file-excel"></span> 
              <span style={{padding:5}}> Export to Excel </span>
              </Button>  

              <Button id="exportBtn" onClick={validate} style={ { marginLeft:20 }}>
              <span className="k-icon k-i-check "></span>  
              <span style={{padding:5}}>  Validate</span>                   
              </Button> 

              <Button id="exportBtn" onClick={process} style={ { marginLeft:20 }}>
              <span className="k-icon k-i-chevron-right"></span>  
              {/* <Icon name="gauge-radial" /> */}

              <span style={{padding:5}}> Process</span>                   
              </Button>        
                                  
            </div>            
          </div>
        </div>
      <style> 
        {`              
            .k-spreadsheet-sheets-bar .k-spreadsheet-sheets-bar-add{
              display:none;
            }              
        `}
      </style>
    </>
  );
}  
