//import './CourseEvaluations.css';
import React from "react";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

export const DeleteCommandCell = (props) => {    
  const [visible, setVisible] = React.useState(false);
  const onDeleteData = () => {
    props.onDelete(props.dataItem);
    setVisible(!visible);
  };
  const toggleDialog = () => {
    setVisible(!visible);
  };
  return (
    <>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"          
        //onClick={() =>  props.onDelete(props.dataItem)}
        onClick={() => setVisible(true)}
      >
        <span className="k-icon k-i-delete"></span>         
      </button>
      {visible && <Dialog title={'Delete Data'} onClose={toggleDialog} width={350}>
        <div style={{ minHeight: 135 }} >
          Are you sure you want to delete the record?
        </div>
        <DialogActionsBar>
          <Button onClick={onDeleteData}>Delete</Button>
          <Button onClick={toggleDialog}>Cancel</Button>
        </DialogActionsBar>
      </Dialog>}
    </>
  );
};

export const EditCommandCell = (props) => {
  return (
    <>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
        onClick={() => props.enterEdit(props.dataItem)}
      >
        <span className="k-icon k-i-edit"></span>          
      </button>
    </>
  );
};

export const ViewCommandCell = (props) => {
  return (
    <>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
        onClick={() => props.enterView(props.dataItem)}
      > {props.name}
        <span className="k-icon k-i-hyperlink-open"></span>          
      </button>
    </>
  );
};

export const filterOperators = {
  text: [
    {
      text: "grid.filterContainsOperator",
      operator: "contains",
    },
    { text: "grid.filterIsEmptyOperator", operator: "isempty" },
    { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
  ],
  numeric: [
    {
      text: "grid.filterEqOperator",
      operator: "eq",
    },
    { text: "grid.filterGteOperator", operator: "gte" },
    { text: "grid.filterLteOperator", operator: "lte" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
  ],
  date: [
    {
      text: "grid.filterEqOperator",
      operator: "eq",
    },
    { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
    { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
    { text: "grid.filterIsNullOperator", operator: "isnull" },
    { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
  ],
  boolean: [
    {
      text: "grid.filterEqOperator",
      operator: "eq",
    },
  ],
};