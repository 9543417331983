//import './CourseEvaluations.css';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSearchParams, useParams} from "react-router-dom";
import { DateTime, Settings } from "luxon";
import { useDeleteGiftAid, useFetchGiftAids } from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, EditCommandCell, ViewCommandCell, filterOperators } from "../../../components/GridComponents.jsx";
import { Button } from '@progress/kendo-react-buttons';
import { MyBreadcrumb } from "../../../components/MyBreadcrumb.jsx";
import { GiftSourceView } from "../giftsources/GiftSourceView.jsx";

const StatefullGrid = WithState(Grid);

const breadcrumbItems = [{
  id: "gift_sources",
  text: " Gift Sources",
  URL: "/gift_sources",
  iconClass: 'k-i-cart'
}, 
{
  id: "gift_aids",
  text: "Gift Aids"
}];

const DepartmentsCell = (props) => {
  const { dataItem } = props;
  const departments = Array.isArray(dataItem.departments) ? dataItem.departments?.map(dept => dept.name).join(', ') : '';
  return (
    <td>
      {departments}
    </td>
  );
};

export const GiftAidsGrid = () => {
  const { giftSourceId } = useParams();
  const deleteMutation = useDeleteGiftAid();      
  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "name", dir: "asc" }
    ],
    filter: {
      logic: 'and',
      filters: [
        { field: 'giftSourceId', operator: 'eq', value: giftSourceId }
      ]
    },
    skip: 0,
    take: 10,
  });

  const [gridData, setGridData] = React.useState([]);

  const navigate = useNavigate();

  const enterView = (item) => {
    navigate(`GA-${item.id}`);
  };

  const enterEdit = (item) => {     
    navigate(`GA-${item.id}/edit`);
  }  

  const RecordCombinedCommandCell = (props) => (
    <td className="record-combined-command-cell">
      <ViewCommandCell {...props} enterView={enterView}  />
      <EditCommandCell {...props} enterEdit={enterEdit} />      
      <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
    </td>
  );

  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );

  const RecordDeleteCommandCell = (props) => (    
    <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
  );  

  return (
    <>      
       <MyBreadcrumb breadcrumbItems={[          
            { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources` },
            { id: "giftaids", text: `GS-${giftSourceId}` },            
          ]} 
        />
       <div style={{margin:'10px 0px'}}>
       <GiftSourceView giftSourceId={giftSourceId} />
      </div>         
      <StatefullGrid
        apiDataSource={useFetchGiftAids}
        filterOperators={filterOperators}
        sortable={true}
        pageable={true}
        resizable={true}
        // data={d => d.map((item) => ({
        //   ...item,
        //   inEdit: item.srsNumber === editID,
        // }))}
        minResizableWidth={30}                        
        dataState={gridDataState}
        gridData={gridData}
        setGridDataState={setGridDataState}
        setGridData={setGridData}
        updateURLSearchParams = {false}        
      > 
       <GridToolbar>       
          <Button title="Add new" icon="plus" themeColor={'primary'} onClick={() => navigate('new')} type="button">
              Gift Aid
          </Button>
        </GridToolbar>
        <GridColumn field="prefixedId" title="ID" filterable={true} width={"160px"} />       
        <GridColumn field="giftSourceId" title="giftSourceId" filterable={true} width={"160px"} />       
        <GridColumn field="name" title="Name" filterable={true} />    
        <GridColumn field="costCenterCode" title="Cost Center Code" filterable={true} />     
        <GridColumn field="aidId" title="Aid Id" filterable={true} />     
        <GridColumn field="departments" title="Departments"  cell={DepartmentsCell} filterable={true} />     
        <GridColumn field="modifiedAt" title="Modified At" filter="date" format="{0:MM/dd/yyyy}" width={"250px"} />
        {/* <GridColumn field="modifiedBy" title="Modified By" filterable={true} />                                  */}
                        
        <GridColumn cell={RecordCombinedCommandCell} filterable={false}  width={"200px"}/>

      </StatefullGrid>
    </>
  );
};
