import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useCreateCostCenter } from "../../../services/awardMgmgtService";
import { CostCenterForm } from "./CostCenterForm";
import ValidationSummary from "../../../components/ValidationSummary";
import ApiStatus from "../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";

export const CostCenterNew = (props) => {
  const createEventMutation = useCreateCostCenter();
  const data = {    
    createdAt: new Date(new Date().getTime() + 1 * 60000),
  };

  return (
    <>
      <CostCenterForm
        data={data}
        eventMutation={createEventMutation}
        isNew={true}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      />
    </>
  );
};
